<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2022-07-29 15:03:57
-->
<template>
	<div class="layout">
		<el-container>
			<!-- 顶部导航栏 -->
			<el-header style="z-index: 2022 !important;">
				<Header />
			</el-header>
			<el-container>
				<!-- 主体部分 -->
				<el-main>
					<Main />
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>
<script>
	import Header from "./components/headers";
	import Main from "./components/main";
	export default {
		name: "LayOut",
		components: {
			Header,
			Main,
		},
		data() {
			return {};
		},
		computed: {},
		methods: {},
		mounted() {},
	};
</script>
<style lang="scss" scoped>
	/deep/.el-submenu__title:hover {
		background: none !important;
	}

	/deep/.el-menu-item:hover {
		background: none !important;
	}
	
	/deep/.el-menu-item {
		height: 50px;
		line-height: 50px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #303133;
	}
	
	// 自定义滚动条样式
	// /deep/::-webkit-scrollbar {
	//     width: 0px;
	//     height: 10px;
	// }
	
	// /deep/::-webkit-scrollbar-thumb {
	//     //滑块部分
	//     background-color: rgba(91, 168, 151, 1);
	// }
	
	// /deep/::-webkit-scrollbar-track {
	//     //轨道部分
	//     background: rgba(91, 168, 151, 0.5);
	// }

	.layout {
		.el-container {
			@extend .layout;
			display: flex;
			flex-direction: column;

			.el-header {
				padding: 0 0;
				margin: 0 0;
				height: 50px !important;
				z-index: 2022 !important;
			}

			.el-main {
				padding: 28px 28px;
				margin: 0 0;
				height: calc(100vh - 50px) !important;
			}
		}
	}
</style>
