<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2021-06-04 18:01:57
-->
<template>
	<div class="header_box flex">
		<!-- <div class="flex_item"> -->
			<div class="header_left">
				<!-- 顶部LOGO -->
				<div class="header_logo">
					<img src="@/assets/images/pc/header/logo.png" alt="" />
				</div>
				<div class="header_title">浙江工业大学勤工助学管理系统</div>
			</div>
			<div class="header_middle">
				<!-- <el-menu :default-active="activeRoutes" class="el-menu-demo" mode="horizontal"
					background-color="#ffffff" text-color="#303133" active-text-color="#0091FF" router>
					<el-menu-item v-for="(item, index) in pcMenus" :index="item.path" :key="item.path">
						{{ item.meta.title }}</el-menu-item>
				</el-menu> -->
				<router-link v-for="(item, index) in pcMenus" :key="index" tag="div" :to="item.path"
					:class="currentRouter == item.name ? 'middle_route_active header_middle_route' : 'header_middle_route'">
					{{ item.meta.title }}
					<transition :name="lastRouter > index ? 'left' : 'right'">
						<span v-show="currentRouter == item.name" class="route_active_inner"></span>
					</transition>
				</router-link>
			</div>
		<!-- </div> -->
		<div class="header_right">
			<el-badge slot="reference" :value="projectUnread" :max="99" class="header_inform" :hidden="isHidden" v-if="userInfo.userType=='COLLEGE_MANAGER'">
				<img src="@/assets/images/pc/header/bell-icon.png" alt="" class="bell-icon" @click="handleBell">
			</el-badge>
			<div class="header_userinfo">
				<el-dropdown trigger="click">
					<span class="header_name">
						{{userInfo.account}}<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>
							<div @click="loginOut">退出登录</div>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
	</div>
</template>
<script>
	import * as pcRouter from "@/router/pcRouter"
	import storage from 'store'
	import {
		noReadNumPc,
		siteMsgReadPc
	} from '@/api/pc/user'
	export default {
		data() {
			return {
				pcMenus: [],
				currentRouter: null,
				lastRouter: null,
				userInfo: {},
				projectUnread:null,
			};
		},
		watch: {
			$route: {
				handler(to, from) {
					this.currentRouter = to.name
					storage.set("localRouterPc", to.fullPath)
					this.animationStart(from)
				},
				immediate: true
			},
		},
		computed: {
			activeRoutes() {
				// console.log("路由233", this.$route);
				return this.$route.path;
			},
			isHidden(){
				return this.projectUnread>0?false:true
			},
		},
		mounted() {
			this.userInfo = storage.get("accessInfo")
			this.getMenus()
			if(this.userInfo.userType=='COLLEGE_MANAGER'){
				this.getMessageNum()
			}
		},
		methods: {
			//获取未读消息数量
			getMessageNum(){
				noReadNumPc().then(res=>{
					if(res.code==0){
						this.projectUnread = res.data
					}
				})
			},
			getMenus() {
				this.pcMenus = []
				let identityPc = storage.get("identityPc")
				if(this.userInfo.superAdmin==false){
					this.pcMenus = pcRouter.default.filter(item => {
						return !item.meta.hidden&&item.meta.title!=='账号管理' && (item.meta.identityPc == identityPc || item.meta.identityPc ==
							"通用")
					});
				}else{
					this.pcMenus = pcRouter.default.filter(item => {
						return !item.meta.hidden &&(item.meta.identityPc == identityPc || item.meta.identityPc ==
							"通用")
					});
				}
			},
			animationStart(from) {
				if (!from) return
				const {
					name
				} = from
				this.pcMenus.forEach((item, index) => {
					if (item.name === name) {
						this.lastRouter = index
					}
				})
			},
			//点击铃铛跳转
			handleBell(){
				let params = {
					id:0
				}
				siteMsgReadPc(params,0).then(res=>{
					if(res.code==0){
						this.$router.push({
							path: `/pc/rejectList`
						})
					}
				})
			},
			loginOut() {
				this.$store.dispatch("pcLogout").then(() => {
					this.$router.push({
						path: `/pc/user/login`
					})
					setTimeout(() => {
						window.location.reload();
					}, 16);
				});
			}
		},
	};
</script>
<style lang="scss" scoped>
	@import "@/styles/index.scss";
	.header_inform {
		margin-right: 16px;
		position: relative;
		cursor: pointer;
	}
	.bell-icon{
		width:20px;
		height:20px;
		// margin-right:16px;
	}
	/deep/.el-badge__content.is-fixed{
		top:3px;
	}
	.flex_item {
		display: flex;
		align-items: center;
	}

	// /deep/.el-menu--horizontal>.el-menu-item.is-active {
	// 	border-width: 20px !important;
	// }

	.header_box {
		height: 50px;
		background: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #DCDFE6;
		padding: 0 28px;
		z-index: 2022 !important;
		position:relative;
		background:#4392FA;
		.header_left {
			display: flex;
			align-items: center;

			.header_logo {
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				margin-right: 10px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.header_title {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 22px;
				color: #ffffff;
			}
		}

		.header_middle {
			// margin-left: 84px;
			display: flex;
			align-items: center;
			position:absolute;
			left: 50%;
			transform: translate(-50%,-50%);
			top:50%;
			.header_middle_route {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #ffffff;
				margin: 0 16px;
				cursor: pointer;
				position: relative;
				line-height: 50px;
				font-weight: bold;
			}

			// .middle_route_active {
			// 	color: #4392FA;
			// }

			.route_active_inner {
				width: 20px;
				height: 4px;
				background: #ffffff;
				border-radius: 1px;
				position: absolute;
				bottom: -2px;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.header_right {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			.header_userinfo {
				height: 50px;
				display: flex;
				align-items: center;

				.header_name {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 20px;
					color: #ffffff;
					cursor: pointer;
				}
			}
		}
	}
</style>
