<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2021-06-10 16:56:20
-->
<template>
	<div class="main_box">
		<!-- <transition name="fade-transform" mode="out-in">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </transition> -->
		<router-view></router-view>
	</div>
</template>
<script>
	export default {
		props: {},
		components: {},
		methods: {
			close() {
				this.$emit('close')
			}
		},
		mounted() {},
	};
</script>
<style lang="scss" scoped>
	@import "@/styles/index.scss";

	.main_box {
		color: #fff;
		overflow-x: hidden;
	}
</style>
